<template>
  <div
    v-if="copyBlocker"
    :data-testid="componentID()"
    @click.right.prevent
    @copy.prevent
    @paste.prevent>
    <component :is="processedHtml"/>
  </div>
  <div v-else :data-testid="componentID()">
    <component :is="processedHtml"/>
  </div>
</template>

<script>
import { compile, provide } from 'vue';

import ArticleSpotlight from '@/components/ArticleSpotlight.vue';
import EventSpotlight from '@/components/EventSpotlight.vue';
import MagnifyImage from '@/components/article-elements/MagnifyImage.vue';
import RestrictedArticle from '@/components/article-elements/RestrictedArticle.vue';
import CardShowcase from '@/components/card-showcase/CardShowcase.vue';
import CardHoverLink from '@/components/card-spotlight/CardHoverLink.vue';
import CardSpotlight from '@/components/card-spotlight/CardSpotlight.vue';
import DeckEmbed from '@/components/deck-embed/DeckEmbed.vue';
import TweetEmbed from '@/components/embed/TweetEmbed.vue';
import TwitchEmbed from '@/components/embed/TwitchEmbed.vue';
import YoutubeEmbed from '@/components/embed/YoutubeEmbed.vue';
import AuthorNewsletter from '@/components/newsletters/AuthorNewsletterWidget.vue';
import PriceHistoryCard from '@/components/price-history/PriceHistoryCard.vue';
import deviceType from '@/mixins/deviceType';
import { PromosBannerVertical, ThirdPartySpotlight } from '@tcgplayer/martech-components';
import delve from 'dlv';

export default {
  name: 'article-body',
  mixins: [ deviceType ],
  props: {
    vertical: {
      type: String,
      required: true,
      default: '',
    },
    html: {
      type: String,
      required: true,
      default: '',
    },
    includePromos: {
      type: Boolean,
      required: false,
      default: false,
    },
    copyBlocker: {
      type: Boolean,
      required: false,
      default: false,
    },
    articleJson: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  setup(props) {
    // Make the article JSON available to all components in the body
    provide('articleJson', props.articleJson);

    if (delve(window, 'TcgApp')) {
      window.TcgApp.component('article-spotlight', ArticleSpotlight);
      window.TcgApp.component('card-hover-link', CardHoverLink);
      window.TcgApp.component('card-showcase', CardShowcase);
      window.TcgApp.component('card-spotlight', CardSpotlight);
      window.TcgApp.component('deck-embed', DeckEmbed);
      window.TcgApp.component('event-spotlight', EventSpotlight);
      window.TcgApp.component('tweet-embed', TweetEmbed);
      window.TcgApp.component('twitch-embed', TwitchEmbed);
      window.TcgApp.component('twitch-channel-embed', TwitchEmbed);
      window.TcgApp.component('promos-banner-vertical', PromosBannerVertical);
      window.TcgApp.component('youtube-embed', YoutubeEmbed);
      window.TcgApp.component('magnify-image', MagnifyImage);
      window.TcgApp.component('author-newsletter', AuthorNewsletter);
      window.TcgApp.component('price-history-card', PriceHistoryCard);
      window.TcgApp.component('restricted-article', RestrictedArticle);
      window.TcgApp.component('third-party-spotlight', ThirdPartySpotlight);
    }
  },
  computed: {
    processedHtml() {
      if (!this.html) return '';

      let html = this.stripScripts(this.html);
      html = html.replace(/\r\n/g, ''); // Convert \r\n to empty string.
      html = this.injectPromos(html); // Inject promos into the body

      return compile ? compile(`<div class="article-body">${html}</div>`) : html;
    },
  },
  methods: {
    verticalPromo() {
      return `<promos-banner-vertical
        v-if="${this.includePromos} && ${this.deviceType === 'mobile'}"
        domain="${this.siteConfig('global.domain')}"
        vertical="${this.vertical.toLowerCase()}"
        promo-size="halfpage"/>`;
    },
    stripScripts(html) {
      const div = document.createElement('div');
      div.innerHTML = html;
      const scripts = div.getElementsByTagName('script');

      for (let i = scripts.length - 1; i >= 0; i--) {
        scripts[i].parentNode.removeChild(scripts[i]);
      }

      return div.innerHTML;
    },
    isOnlyText(element) {
      // Reject if there's an embed and it's not in our list of ones that are OK to be next to
      const embed = element.querySelector('[data-embed]');
      if (embed && ![ 'card-hover', 'card-hover-simple', ].includes(embed.getAttribute('data-embed'))) {
        return false;
      }

      // Check for <img> tags
      const images = element.getElementsByTagName('img');
      if (images && images.length) return false;

      return true;
    },
    getPromoPosition(elements, positionAfter) {
      if (positionAfter > elements.length) return false;

      const validAfterTags = [ 'P' ];
      const validBeforeTags = [ 'H1', 'H2', 'H3', 'H4', 'H5', 'P' ];

      if (elements[positionAfter] && elements[positionAfter + 1]
        && validAfterTags.includes(elements[positionAfter].tagName)
        && validBeforeTags.includes(elements[positionAfter + 1].tagName)
        && this.isOnlyText(elements[positionAfter])
        && this.isOnlyText(elements[positionAfter + 1])) {
        return positionAfter;
      }

      return this.getPromoPosition(elements, positionAfter + 1);
    },
    injectPromos(html) {
      const minDistance = 3; // Minimum paragraphs between promos
      const targetPlacement = [ 0, 0.45, 0.7 ]; // Target page percentage for promos
      const placement = []; // Where we actually place the promo

      const original = document.createElement('div');
      const withPromos = document.createElement('div');

      original.innerHTML = html;

      const elements = original.children;
      const paragraphCount = original.getElementsByTagName('p').length;

      // Figure out the placements
      for (let i = 0; i < targetPlacement.length; i++) {
        // Original intended position
        let positionAfter = Math.ceil(paragraphCount * targetPlacement[i]);
        const lastPromo = i === 0 ? -minDistance : placement[i - 1];

        // Find a position that just has text around it
        positionAfter = this.getPromoPosition(elements, positionAfter); // + i to handle the offset of elements between origin and withPromos

        // Have we passed the end?
        if (positionAfter === false) {
          break;
        }

        // Make sure we have the minimum distance between promos
        if (positionAfter - lastPromo < minDistance) {
          positionAfter += minDistance - (positionAfter - lastPromo);
        }

        placement.push(positionAfter);
      }

      // Build the output
      for (let i = 0; i < elements.length; i++) {
        withPromos.appendChild(elements[i].cloneNode(true));

        if (i !== placement[0]) continue; /* eslint-disable-line */

        placement.shift();
        const div = document.createElement('div');
        div.innerHTML = this.verticalPromo();
        withPromos.appendChild(div);
      }

      return withPromos.innerHTML;
    },
  },
};
</script>

<!-- eslint-disable-next-line -->
<style lang="scss">
/* Body is the processed HTML from the service, it doesn't know what it needs */
/* eslint-disable */
.article-body {
  position: relative;
  font-family: $martech-display-inter;
  color: $martech-text-primary;
  padding-bottom: 0;
  margin: 0;
  font-weight: $martech-weight-normal;
  overflow-wrap: break-word;
  word-wrap: break-word;

  img {
    max-width: 100%;
  }

  &:last-of-type {
    padding: 0 $martech-spacer-3;

    @include breakpoint(1200) {
      padding: 0;
    }
  }

  > .text-align-center {
    text-align: center;
  }
  > .text-align-right {
    text-align: right;
  }
  > .text-align-justify {
    text-align: justify;
  }

  .back {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }

  .ad-space {
    height: 250px;
    width: 100%;
  }

  > p a {
    color: $martech-link;
    text-decoration: none;
    font-weight: $martech-weight-semibold;
    font-family: $martech-display-inter;
    font-size: $martech-type-18;
    line-height: 30px;

    @include breakpoint(1024) {
      font-size: $martech-body;
      line-height: 27px;
    }

    &:hover {
      text-decoration: underline;
      color: $martech-blue-hover;
    }

    &:focus-visible {
        outline: 2px dashed $martech-blue-120;
    }
  }

  > p em {
    font-family: 'Inter Tight', BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  }

  > p {
    &:after {
        content: attr(data-caption);
        font-size: $martech-type-12;
        font-weight: $martech-weight-normal;
        color: $martech-gray-150;
        width: 100%;
        line-height: 12px;
        text-align: center;
        display: flex;
        justify-content: center;
    }

    > br {
      display: none;
    }

    > sub {
        font-size: $martech-type-12;
        font-weight: $martech-weight-normal;
        color: $martech-gray-150;
        width: 100%;
        line-height: 12px;
        text-align: center;
        display: flex;
        justify-content: center;
        margin-top: $martech-spacer-2;
    }
  }

  > h1, > h2, > h3, > h4,
  > h1 a, > h2 a, > h3 a, > h4 a {
    padding-bottom: $martech-spacer-2;
    word-break: break-word;
    font-family: $martech-display-semi-condensed;
    letter-spacing: inherit;
  }

  > h1 a, > h2 a, > h3 a, > h4 a {
    &:after {
      content: url(https://tcgplayer-cdn.tcgplayer.com/infinite/images/url-icon.svg);
      position: relative;
      margin-left: $martech-spacer-2;
    }
  }

  > h2, > h2 a {
    font-family: $martech-display-semi-condensed;
    font-size: $martech-type-24;
    line-height: 32px;
    font-weight: $martech-weight-semibold;
    color: $martech-text-primary;

    @include breakpoint(1024) {
      font-size: $martech-type-32;
      line-height: 40px;
    }
  }

  > h3, > h3 a {
    font-size: $martech-type-24;
    font-weight: $martech-weight-semibold;
    line-height: 30px;
  }

  > h4, > h4 a {
    font-size: $martech-type-20;
    font-weight: $martech-weight-semibold;
    line-height: 26px;
  }

  > p {
    font-family: $martech-display-inter;
    font-size: $martech-type-18;
    line-height: 30px;
    color: $martech-text-primary;
    padding-bottom: $martech-spacer-4;
    margin: 0;
    font-weight: $martech-weight-normal;
    overflow-wrap: break-word;
    word-wrap: break-word;

    @include breakpoint(1024) {
      font-size: $martech-type-16;
      line-height: 27px;
    }

    > .text-align-center {
      text-align: center;
    }
    > .text-align-right {
      text-align: right;
    }
    > .text-align-justify {
      text-align: justify;
    }
  }

  > p:last-of-type {
    padding-bottom: 0;
  }

  > ul,
  > ol {
    margin-left: 25px;
    padding-left: 0;
    padding-bottom: $martech-spacer-3;

    @include breakpoint(1024) {
      margin-left: 40px;
    }

    a {
      font-weight: $martech-weight-semibold;
      font-size: $martech-type-18;
      line-height: 32px;
      color: $martech-link;
      transition: color 0.15s ease-in-out;

      &:hover {
        color: $martech-blue-hover;
      }

      @include breakpoint(1024) {
        font-size: $martech-body;
        line-height: 27px;
      }
    }

    li {
      font-family: $martech-display-inter;
      font-size: $martech-type-18;
      line-height: 30px;
      color: $martech-text-primary;
      font-weight: $martech-weight-normal;

      @include breakpoint(1024) {
        font-size: $martech-type-16;
        line-height: 27px;
      }

      ul {
        padding-left: $martech-spacer-4;
      }
    }
  }

  > blockquote {
    background: $martech-white;
    border-left: 4px solid $martech-gray-160;
    position: relative;
    padding: $martech-spacer-2 $martech-spacer-3;

    p {
      font-family: $martech-display-semi-condensed;
      letter-spacing: 0.5px;
      font-size: $martech-type-32;
      font-weight: $martech-weight-bold;
      line-height: 2.25rem;
      margin: 0;
    }
  }

  > .image-full-width {
    img {
      width: 100%;
    }
  }

  > img,
  > p > img,
  > p > a > img,
  > a > img,
  > center > img {
    width: 100%;
    height: auto;
    display: block;
    margin: auto;
    padding-bottom: $martech-spacer-3;

    @include breakpoint(1024) {
      width: 562px;
      height: auto;
    }
  }

  > .video-responsive,
  > p > .video-responsive {
    position: relative;
    padding-top: 56.25%;
    overflow: hidden;
    margin-bottom: $martech-spacer-5;
  }

  > video, p > video {
    width: 100%;
  }

  > .video-responsive > iframe,
  > p > .video-responsive > iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: 0;
  }

  > .cards-side-by-side {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @include breakpoint(768) {
      flex-direction: row;
      flex-wrap: wrap;
    }

    img {
      width: 200px;
      display: block;
      margin: 0;
      padding-bottom: $martech-spacer-2;

      @include breakpoint(768) {
        margin-right: $martech-spacer-2;
      }
    }

    img:last-of-type {
      margin-right: 0;
    }
  }

  > .card-265x400 {
    img {
      display: block;
      margin: auto;
      width: 265px;
      height: 400px;
    }
  }

  table {
    width: 100%;
    border: none;

    thead {
      text-align: left;
      border-bottom: 1px solid $martech-border;
      background-color: $martech-surface-alt;

      th {
        font-weight: $martech-weight-semibold;
        font-size: $martech-type-14;

        @include breakpoint(1024) {
          font-size: $martech-type-16;
        }
      }
    }

    td, th {
      border: none;
      padding: $martech-spacer-3 $martech-spacer-3;
    }

    td {
      font-size: $martech-type-14;
      border-bottom: 1px solid $martech-border;
    }
  }
}
/* eslint-enable */
</style>
