<template>
  <div ref="root" class="promoted-topics" :data-testid="componentID('promoted-topics')" :class="{'chips-only' : onlyChips}">
    <template v-if="visible">
      <h3 v-if="!onlyChips">
        Explore more topics on TCGplayer
      </h3>
      <div class="chip-container">
        <tcg-chip v-for="topic in filteredTopics" :key="topic" size="sm" @click="topicLinkURL(topic.label)">
          <span class="chip-text">
            <img v-if="topic.iconImageURL" :src="topic.iconImageURL" alt=""> {{ topic.label }}
          </span>
        </tcg-chip>
      </div>
      <base-link v-if="!onlyChips" link-url="/topics" class="see-all martech-text-semibold">
        See All Topics
      </base-link>
    </template>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { get } from '@vueuse/core';
import { useRouter } from 'vue-router';
import useGetTopics from '@/use/topics/useGetTopics';

import TcgChip from '@tcgplayer/design-system/src/components/Chip/Chip.vue';
import { useDelayLoad, BaseLink } from '@tcgplayer/martech-components';

const props = defineProps({
  excludedTopics: {
    type: Array,
    required: false,
    default: () => [],
  },
  includedTopics: {
    type: Array,
    required: false,
    default: () => [],
  },
  onlyChips: {
    type: Boolean,
    default: false,
  },
});

const root = ref();
const { visible } = useDelayLoad(root);

const router = useRouter();

const { topics } = useGetTopics('best cards');

const topicLinkURL = topic => router.push({ name: 'topic', params: { tag: `${topic}` } });

const filteredTopics = computed(() => {
  if (props.includedTopics.length > 0) {
    return get(topics)?.filter(t => (props.includedTopics || [])?.includes(t.label));
  }

  return get(topics)?.filter(t => !(props.excludedTopics || [])?.includes(t.text)).filter(t => t.text !== 'all topics');
});
</script>

<style lang="scss" scoped>
.promoted-topics {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: $martech-spacer-4 0 0 0;

  &.chips-only {
    min-height: 50px;
    align-items: flex-start;
    padding: $martech-spacer-4 $martech-spacer-3 0 $martech-spacer-3;

    @include breakpoint(1024) {
      padding: $martech-spacer-4 0 0 0;
    }

    .chip-container {
      justify-content: flex-start;
    }
  }

  h3 {
    margin-bottom: $martech-spacer-4;
  }

  .chip-container {
    display: flex;
    flex-wrap: wrap;
    grid-gap: $martech-spacer-3;
    justify-content: center;
  }

  :deep(.tcg-chip) {
    background: $martech-gray-10;
    color: $martech-text-primary;
    text-transform: capitalize;
    cursor: pointer;

    &:last-of-type {
      margin-right: 0;
    }
  }

  .chip-text {
    display: flex;
    align-items: center;

    img {
      width: 16px;
      height: 16px;
      margin-right: $martech-spacer-1;
    }
  }

  .see-all {
    margin-top: $martech-spacer-4;
  }
}
</style>