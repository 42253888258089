<template>
  <div ref="root" class="latest-events" :data-testid="componentID()">
    <template v-if="visible">
      <div class="header">
        <h2 v-if="format" class="martech-sub-heading martech-semi-condensed martech-text-uppercase martech-text-semibold">
          Latest {{ format }} Events
        </h2>
        <h2 v-else class="martech-sub-heading martech-semi-condensed martech-text-uppercase martech-text-semibold">
          Latest Tournament Decks
        </h2>
        <router-link
          v-if="isDesktop && seeMore"
          :to="seeMore"
          class="martech-see-all-link"
          :aria-label="`See All Latest ${format} Events`">
          See All
        </router-link>
      </div>
      <div class="latest-events--grid">
        <!--placeholder components-->
        <div v-if="loading" class="events placeholder-wrapper">
          <div v-if="hasLargeFeature" class="event-large">
            <full-art-placeholder :include-text="false"/>
          </div>
          <div class="event-small" :class="[{'no-feature' : !hasLargeFeature}, {'event-landing' : eventPage}]">
            <div v-for="p in placeholderCount" :key="p" class="item">
              <full-art-placeholder :include-text="false"/>
            </div>
          </div>
        </div>
        <!--event components-->
        <div v-show="!loading" class="events">
          <template v-if="hasLargeFeature">
            <div v-for="event in events.slice(0,1)" :key="event.uuid" class="event-large">
              <grid-event-card :data="event" :show-vertical-link="true" @click="handleEventClick"/>
            </div>
          </template>
          <div class="event-small" :class="[{'no-feature' : !hasLargeFeature}, {'event-landing' : eventPage}]">
            <div v-for="event in formattedEvents" :key="event.uuid" class="item">
              <grid-event-card :data="event" :show-vertical-link="true" @click="handleEventClick"/>
            </div>
          </div>
        </div>
        <router-link
          v-if="isMobile && seeMore"
          :to="seeMore"
          :aria-label="`See All Latest ${format} Events`"
          class="martech-see-all-link see-all-mobile">
          See All
        </router-link>
      </div>
    </template>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import Api from '@/api/api';
import { useDeviceType, useNiceLoad, GridEventCard } from '@tcgplayer/martech-components';
import FullArtPlaceholder from '@/components/placeholder-components/FullArtPlaceholder.vue';
import amplitudeEvent from '@tcgplayer/amplitude';

const props = defineProps({
  verticalName: {
    type: String,
    required: false,
    default: '',
  },
  contentType: {
    type: String,
    required: false,
    default: 'Event',
  },
  seeMore: {
    type: [ String, Object ],
    required: false,
    default: '',
  },
  placement: {
    type: String,
    required: false,
    default: 'Homepage',
  },
  contentTypes: {
    type: String,
    required: false,
    default: '',
  },
  format: {
    type: String,
    required: false,
    default: '',
  },
  rows: {
    type: Number,
    required: true,
    default: 5,
  },
  hasLargeFeature: {
    type: Boolean,
    required: false,
    default: true,
  },
  exclude: {
    type: Array,
    required: false,
    default: () => [],
  },
  includedVerticals: {
    type: String,
    required: false,
    default: '',
  },
  slice: {
    type: Number,
    required: false,
    default: null,
    note: 'Used to pass the exact number of items you want if you need to exclude an id from the grid.',
  },
  eventPage: {
    type: Boolean,
    required: false,
    default: false,
  },
  placeholderCount: {
    type: Number,
    required: false,
    default: 6,
  },
});

const { isMobile, isDesktop } = useDeviceType();

// Refs
const root = ref();
const events = ref([]);
const loading = ref(true);

// Computed
const formattedEvents = computed(() => (props.hasLargeFeature ? events.value.slice(1, props.rows) : events.value.slice(0, props.rows)));

// Methods
const handleEventClick = (event) => {
  const data = {
    title: event.title,
    productLine: event.vertical,
    format: event.format,
    url: event.canonicalURL,
    source: 'Latest Events',
  };
  amplitudeEvent('infinite', 'infiniteEventClicked', data);
};

const loader = async () => {
  const res = await Api.getEventsFeed({
    game: props.verticalName,
    rows: props.rows,
    format: props.format,
    sort: 'latest',
    order: 'desc',
    games: props.includedVerticals,
  });

  // We are passing this.slice or this.rows based on where this component is used.
  // For event landing pages, we need to request 1 extra to exclude the event id if it matches and then slice at 6 if no matching id.
  // For homepages/verticals we do not want to slice this or exclude IDs, and want to let it take the full number of rows we request.
  events.value = (res?.data?.result || []).filter(item => !props.exclude.includes(parseInt(item.eventID))).slice(0, props.slice || props.rows);

  loading.value = false;
};

const { visible } = useNiceLoad({
  callback: loader,
  rootElement: root,
});
</script>

<style lang="scss" scoped>
.latest-events {
  position: relative;
  width: 100%;
  overflow: hidden;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: $martech-spacer-2;
  }

  .events {
    display: flex;
    list-style: none;
    flex-direction: column;

    @include breakpoint(1024) {
      flex-direction: row;
    }

    &.placeholder-wrapper {
      .event-large {
        :deep(.placeholder) {
          height: 209px;

          @include breakpoint(1024) {
            height: 615px;
          }
        }
      }

      .event-small {
        .item {
          height: 100%;

          @include breakpoint(1024) {
            height: 192px;
          }

          :deep(.placeholder) {
            height: 192px;
          }
        }
      }
    }

    .event-large {
      padding-bottom: $martech-spacer-3;

      @include breakpoint(1024) {
        padding-bottom: 0;
        padding-right: $martech-spacer-3;
        width: 65%;
        height: 611px;

        :deep(.event) {
          display: flex;
          align-items: stretch;
          height: 100%;

          h3 {
            @include breakpoint(1024) {
              font-size: $martech-type-32;
              line-height: 2.125rem;
            }
          }

          .background-overlay {
            height: 300px;
          }

          :deep(.new-tag) {
            width: 5rem;
            height: 2rem;
          }
        }
      }

      @include breakpoint(1200) {
        height: 615px;
        width: 50%;
      }
    }

    .event-small {
      display: flex;
      flex-direction: column;
      width: 100%;

      @include breakpoint(1024) {
        width: 50%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: $martech-spacer-3;

        &.no-feature {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: $martech-spacer-3;
          width: 100%;
          height: auto;
        }
      }

      @include breakpoint(1200) {
        &.event-landing {
          grid-template-columns: repeat(4, 1fr);
        }
      }

      .item {
        width: 100%;
        padding-bottom: $martech-spacer-3;

        @include breakpoint(1024) {
          padding-bottom: 0;
        }
      }

      &.vertical {
        @include breakpoint(768) {
          flex-direction: column;
          flex-wrap: wrap;

          .item {
            width: 50%;

            &:nth-child(1),
            &:nth-child(3) {
              padding-right: $martech-spacer-2;
            }

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(4),
            &:nth-child(5) {
              padding-bottom: $martech-spacer-2;
            }
          }
        }
      }
    }
  }

  .see-all-mobile {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding-top: $martech-spacer-3;
  }
}
</style>
