import { DateHelper as dates } from '@tcgplayer/martech-components';
import delve from 'dlv';

export default {
  // parseDeckAPIResponse takes a response from the DecksAPI and parses it into a form usable by the frontend.
  parseDeckAPIResponse(response, deckId, article, author) {
    const names = Object.keys(delve(response, 'data.result.deck.subDecks') || {}).sort();
    const cards = delve(response, 'data.result.cards') || {};
    const subDecks = {};
    const subDeckPrices = {};

    const deckData = {
      id: deckId,
      article,
      author,
      deckSource: delve(response, 'data.result.deck.deckSource', ''),
      canonicalURL: delve(response, 'data.result.canonicalURL', ''),
      companionID: delve(response, 'data.result.deck.companionID', ''),
      color: delve(response, 'data.result.deck.stats.colors.color', ''),
      eventName: delve(response, 'data.result.deck.eventName', ''),
      eventRank: delve(response, 'data.result.deck.eventRank', 0),
      date: delve(response, 'data.result.deck.eventDate') || delve(response, 'data.result.deck.created', dates.newDate()),
      external: this.isExternalId,
      format: delve(response, 'data.result.deck.format', ''),
      game: delve(response, 'data.result.deck.game', '').toLowerCase(),
      marketPriceHigh: delve(response, 'data.result.deck.stats.price.marketPrice') || 0.00,
      name: delve(response, 'data.result.deck.name', ''),
      playerName: response?.data?.result?.deck?.playerName,
      playerHandle: response?.data?.result?.deck?.playerHandle || '',
      rarity: delve(response, 'data.result.deck.rarity', ''),
      stats: delve(response, 'data.result.deck.stats', ''),
      subDecks: {},
      subDeckPrices: {},
    };

    names.forEach((name) => {
      subDecks[name] = [];
      subDeckPrices[name] = 0;
      (delve(response, `data.result.deck.subDecks.${name}`) || []).forEach((card) => {
        subDecks[name].push(Object.assign(card, cards[card.cardID]));
        const p = card.marketPrice || 0;
        const q = card.quantity || 0;
        subDeckPrices[name] += p * q;
      });

      subDecks[name].sort(this.sortByName);
      subDecks[name].forEach((card, idx, subCards) => {
        subCards[idx].imageURL = card.tcgImageURL || card.externalImageURL || card.imageURL || card.scryfallImageURL;
        subCards[idx].flipImageURL = card.tcgFlipImageURL || card.externalFlipImageURL || card.flipImageURL || card.scryfallFlipImageURL;
        subCards[idx].isFlipped = false;
      });
    });

    deckData.subDecks = subDecks;
    deckData.subDeckPrices = subDeckPrices;

    return deckData;
  },
};
