import amplitudeEvent from '@tcgplayer/amplitude';
import { PageRequestID } from '@tcgplayer/martech-components';
import delve from 'dlv';
import { createRouter, createWebHistory } from 'vue-router';
import Api from './api/api';
import verticalFeeds from './api/verticals';
import flags from './config/views/landing-pages/decks';
import ArticleReadPage from './views/ArticleReadPage.vue';
import HomePage from './views/landing-pages/cfb/CfbHomepage.vue';

const magicFormatsList = [ 'Commander', 'Historic', 'Legacy', 'Limited', 'Modern', 'Pioneer', 'Standard', 'Other' ];

const magicDecksFormats = [];
magicFormatsList.forEach((format) => {
  magicDecksFormats.push({
    path: `decks/format/${encodeURIComponent(format.toLowerCase().replaceAll(' ', '-'))}`,
    name: `Magic: The Gathering ${format} Decks`,
    meta: {
      showMainNavigation: true, social: 'magic', gtmName: 'Magic', showVerticalLink: true, vertical: 'magic',
    },
    props: {
      contentType: 'deck',
      verticalName: 'magic',
      format,
      flags: [ 'Pioneer', 'Standard', 'Modern' ].includes(format) ? flags.fixed : flags.decks,
      loader: verticalFeeds.DeckFeedLoader({
        game: 'magic',
        format: format.toLowerCase(),
        latest: true,
        isAdmin: [ 'Standard', 'Modern', 'Pioneer' ].includes(format),
        testDecks: false,
      }),
    },
    component: () => import('./views/landing-pages/Format.vue'),
  });
});

const magicArticlesFormats = [];
magicFormatsList.forEach((format) => {
  // This is the format landing pages off the main homepage route
  magicArticlesFormats.push({
    path: `${encodeURIComponent(format.toLowerCase().replaceAll(' ', '-'))}`,
    name: `Magic: The Gathering ${format}`,
    meta: {
      showMainNavigation: true, showVerticalLink: true, vertical: 'magic',
    },
    props: {
      verticalName: 'magic',
      format: format.toLowerCase(),
      root: false,
      contentType: [ 'Article', 'Video', 'Podcast' ],
    },
    component: () => import('./views/landing-pages/cfb/ArticleFormat.vue'),
  });
  // This is the normal article w/ grid card feed
  magicArticlesFormats.push({
    path: `articles/format/${encodeURIComponent(format.toLowerCase().replaceAll(' ', '-'))}`,
    name: `Magic: The Gathering ${format} Articles`,
    meta: {
      showMainNavigation: true, showVerticalLink: true, vertical: 'magic',
    },
    props: {
      verticalName: 'magic',
      format: format.toLowerCase(),
      root: false,
      contentType: [ 'Article', 'Video', 'Podcast' ],
    },
    component: () => import('./views/landing-pages/VerticalLanding.vue'),
  });
});

const magicEventsFormats = [];
[ 'Standard', 'Modern', 'Pioneer', 'Legacy', 'Limited', ].forEach((format) => {
  magicEventsFormats.push({
    path: `events/format/${encodeURIComponent(format.toLowerCase())}`,
    name: `Magic ${format} Events`,
    meta: {
      showMainNavigation: true, social: 'magic', gtmName: 'Magic', showVerticalLink: true, vertical: 'magic',
    },
    props: {
      contentType: 'event',
      verticalName: 'magic',
      format,
      flags: flags.events,
      loader: verticalFeeds.EventsFeedLoader({
        game: 'magic',
        format: format.toLowerCase(),
        isAdmin: [ 'Standard', 'Modern', 'Pioneer' ].includes(format),
        sort: 'created',
        order: 'desc',
      }),
    },
    component: () => import('./views/landing-pages/Format.vue'),
  });
});

const compare = (obj1, obj2) => (
  Object.keys(obj1).length === Object.keys(obj2).length
  && Object.keys(obj1).every(key => (
    obj2.hasOwnProperty(key) && obj1[key] === obj2[key]
  ))
);

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    // If we are at the same path with the same query params and the only thing that has changed is the hash then do not scroll
    if (to.path === from.path && to.hash !== from.hash && compare(to.params, from.params)) {
      return {};
    }

    // sets a delay for the page to scroll back to the top, allowing the pages data to fully load and stop pages from getting stuck on a component
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ top: 0, left: 0 });
      }, 150);
    });
  },
  routes: [
    {
      path: '/',
      name: 'Home',
      component: HomePage,
      meta: { showMainNavigation: true },
    },
    {
      path: '/article/:title/:id',
      component: ArticleReadPage,
      meta: { showMainNavigation: true, articlePage: true, customPageTrack: true },
    },
    {
      path: '/author/:name/:id?',
      name: 'Authors',
      component: () => import('./views/landing-pages/AuthorLandingRouterView.vue'),
      meta: { showMainNavigation: true },
      children: [
        {
          path: '/author/:name/:id?',
          name: 'Authors',
          meta: { showMainNavigation: true },
          component: () => import('./views/landing-pages/AuthorLanding.vue'),
        },
      ],
    },
    {
      path: '/search',
      component: () => import('./views/Search/Results.vue'),
      meta: { showMainNavigation: true, searchPage: true },
    },
    {
      path: '/magic-the-gathering/deck/:name?/:id',
      name: 'Magic: The Gathering Deck',
      meta: {
        showMainNavigation: true, social: 'magic', gtmName: 'Magic', vertical: 'magic', customPageTrack: true,
      },
      props: { verticalName: 'magic', contentType: 'Deck' },
      component: () => import('./views/landing-pages/DeckLanding.vue'),
    },
    {
      path: '/cfb-library',
      component: () => import('./views/landing-pages/cfb/Library.vue'),
      name: 'cfb library',
      props: true,
      meta: { showMainNavigation: true },
    },
    {
      path: '/magic-the-gathering/:format',
      name: 'Format Landing',
      component: () => import('./views/landing-pages/cfb/ArticleFormat.vue'),
      meta: { showMainNavigation: true },
      props: route => ({
        format: route.params.format,
      }),
    },
    {
      path: '/magic-the-gathering/pro/:format',
      redirect: { name: 'Format Landing' },
    },
    {
      path: '/tag/cfb%20pro',
      redirect: { name: 'Home' },
    },
    {
      path: '/tag/:tag',
      component: () => import('./views/landing-pages/TagLanding.vue'),
      name: 'tag',
      props: true,
      meta: { showMainNavigation: true },
      children: [
        {
          path: '/tag/:tag/format/:format',
          name: 'tag formats',
          meta: { showMainNavigation: true },
          component: () => import('./views/landing-pages/TagLanding.vue'),
          props: true,
        },
      ],
    },
    {
      path: '/:vertical/series/:series',
      redirect: to => ({ name: 'series', params: { series: to.params.series } }),
    },
    {
      path: '/series/:series',
      component: () => import('./views/landing-pages/TagLanding.vue'),
      name: 'series',
      props: true,
      meta: { showMainNavigation: true },
    },
    {
      path: '/archive',
      component: () => import('./views/landing-pages/cfb/ArticleArchive.vue'),
      name: 'Article Archive',
      props: true,
      meta: { showMainNavigation: true },
      children: [
        {
          path: '/archive/:series',
          component: () => import('./views/landing-pages/TagLanding.vue'),
          name: 'Archive Series',
          props: true,
          meta: { showMainNavigation: true },
        },
      ],
    },
    {
      path: '/magic-the-gathering',
      name: 'Magic Landing Parent',
      children: [
        {
          path: '/magic-the-gathering',
          name: 'Magic Landing',
          meta: {
            showMainNavigation: true, social: 'magic', gtmName: 'Magic', vertical: 'magic',
          },
          props: { verticalName: 'magic' },
          component: () => import('./views/landing-pages/cfb/ProLanding.vue'),
        },
        {
          path: 'articles',
          name: 'Magic: The Gathering Articles',
          meta: {
            showMainNavigation: true, social: 'magic', gtmName: 'Magic', decks: false, vertical: 'magic',
          },
          props: { verticalName: 'magic', contentType: [ 'Article', 'Video', 'Podcast' ] },
          component: () => import('./views/landing-pages/VerticalLanding.vue'),
        },
        {
          path: 'decks',
          name: 'Magic: The Gathering Decks',
          meta: {
            showMainNavigation: true, social: 'magic', gtmName: 'Magic', vertical: 'magic',
          },
          props: {
            verticalName: 'magic', flags: flags.default, loader: 'events',
          },
          component: () => import('./views/landing-pages/Decks.vue'),
        },
        {
          path: 'deck',
          redirect: 'decks',
        },
        {
          path: 'decks/advanced-search',
          name: 'Magic: The Gathering Advanced Deck Search',
          component: () => import('./views/Search/AdvancedDeck.vue'),
          meta: { showMainNavigation: true },
          props: { game: 'magic' },
        },
        {
          path: 'events',
          name: 'Magic: The Gathering Tournaments',
          meta: {
            showMainNavigation: true, social: 'magic', gtmName: 'Magic', vertical: 'magic',
          },
          props: {
            verticalName: 'magic', events: true, loader: 'events', flags: flags.events,
          },
          component: () => import('./views/landing-pages/Decks.vue'),
        },
        {
          path: 'decks/event',
          redirect: { name: 'Magic: The Gathering Tournaments' },
        },
        {
          path: 'events/event/:id/',
          name: 'Magic: The Gathering Event Decks',
          meta: {
            showMainNavigation: true, social: 'magic', gtmName: 'Magic', decks: false, vertical: 'magic', customPageTrack: true,
          },
          props: { verticalName: 'magic' },
          component: () => import('./views/landing-pages/Event.vue'),
        },
        {
          path: 'decks/event/:id',
          redirect: to => ({ path: `/magic-the-gathering/events/event/${encodeURIComponent(to.params.id)}` }),
        },
        {
          path: 'decks/player/:id/',
          name: 'Magic: The Gathering Player Decks',
          meta: {
            showMainNavigation: true, social: 'magic', gtmName: 'Magic', vertical: 'magic',
          },
          props: { verticalName: 'magic', loader: 'decks', flags: flags.player },
          component: () => import('./views/landing-pages/Decks.vue'),
        },
        ...magicDecksFormats,
        ...magicEventsFormats,
        ...magicArticlesFormats,
      ],
    },
    {
      path: '/magic-the-gathering/pro',
      redirect: { name: 'Magic Landing' },
    },
    { path: '/edge', component: () => import('./views/landing-pages/cfb/EdgeMasterClasses.vue'), meta: { showMainNavigation: true } },
    { path: '/uhoh', component: () => import('./views/PageLevelError.vue'), meta: { showMainNavigation: true } },
    { path: '/:pathMatch(.*)*', component: () => import('./views/PageNotFound.vue'), meta: { showMainNavigation: true } },
  ],
});

router.beforeEach((to, from, next) => {
  if (to !== from) {
    // Regenerate the PageRequestID
    window.TCGPageRequestID = PageRequestID.pageRequestID(to.fullPath);
  }

  // Subscription and account should be taken care of on Infinite
  if (to.fullPath.startsWith('/premium/subscription') || to.fullPath.startsWith('/account/')) {
    window.location.href = `${process.env.VUE_APP_TCGPLAYER_INFINITE_URL}${to.fullPath}`;
    return;
  }

  if (to.fullPath.startsWith('/flesh-and-blood')) {
    let href = to.fullPath;
    const re = /flesh-and-blood\/([\w+-]+)$/;
    if (re.test(href)) {
      const match = href.match(re);
      const format = match[match.length - 1];
      href = `/flesh-and-blood/articles/format/${format}`;
    }
    window.location.href = `${process.env.VUE_APP_TCGPLAYER_INFINITE_URL}${href}`;
    return;
  }

  Api.clearUsedResults();

  let fromName = from.name;
  let fromPath = from.fullPath;

  // If from path is the homepage we need to figure out if we really came from
  // a different page or not due to opening in a new tab/window
  if (delve(from, 'fullPath') === '/') {
    try {
      const referrer = new URL(document.referrer);
      if (delve(referrer, 'hostname') === delve(window, 'location.hostname')) {
        fromPath = referrer.pathname;
        fromName = '';
      }
    } catch (err) { /* catch if document.referrer is empty string */ }
  }

  to.meta.fromPath = fromPath;
  to.meta.fromName = fromName;

  // If customPageTrack is set we expect something within that page to make a page call with custom data
  if (!to.meta.customPageTrack) {
    amplitudeEvent('infinite', 'infinitePageView', {
      referrer: document.referrer,
      path: to.fullPath,
      name: to.name,
      fromPath,
      fromName,
      facebookBoost: delve(to, 'query.facebookBoost') === 'true',
      source: delve(to, 'query.source'),
    });
  }

  next();
});

export default router;
