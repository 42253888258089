<template>
  <delay-load @visible="onVisible">
    <div v-if="articles.length >= 3" class="series-carousel" :data-testid="componentID()">
      <div class="container">
        <div class="header">
          <div class="group">
            <h2 class="martech-sub-heading martech-semi-condensed martech-text-uppercase martech-text-semibold">
              More In The Series:
            </h2>
            <img :src="overlayImageURL" :alt="title">
          </div>
          <router-link
            v-if="isDesktop"
            class="martech-see-all-link"
            aria-label="See All In The Series"
            :to="seriesTo">
            See All
          </router-link>
        </div>
      </div>
      <div class="container" :class="{'mobile-scroll' : isMobile}">
        <div class="carousel" :style="{'grid-template-columns' : `repeat(${rows}, 1fr)`}">
          <slim-article-card v-for="article in articles" :key="article.canonicalURL" :data="article" class="slide"/>
        </div>
      </div>
      <router-link
        v-if="isMobile"
        class="view-all martech-see-all-link"
        aria-label="See All In The Series"
        :to="seriesTo">
        See All
      </router-link>
    </div>
  </delay-load>
</template>

<script setup>
import { ref, computed } from 'vue';
import Api from '@/api/api';
import tags from '@/api/tags';
import DelayLoad from '@/components/DelayLoad.vue';
import SlimArticleCard from '@/components/card-elements/SlimArticleCard.vue';
import { useDeviceType } from '@tcgplayer/martech-components';
import useSeriesRoute from '@/use/series/useSeriesRoute';

const props = defineProps({
  series: {
    type: String,
    default: () => {},
    required: false,
  },
  vertical: {
    type: String,
    default: '',
    required: false,
  },
  exclude: {
    type: String,
    default: '',
    required: false,
  },
  rows: {
    type: Number,
    default: 3,
    required: false,
  },
});

const { isMobile, isDesktop } = useDeviceType();

const articles = ref([]);
const loading = ref(true);
const overlayImageURL = ref();
const title = ref();

const { seriesRoutePathName } = useSeriesRoute(props.series);
const seriesTo = computed(() => ({ name: 'series', params: { series: seriesRoutePathName.value } }));

tags.SeriesMetadataLoader({ seriesName: props.series })().then((metadata) => {
  title.value = metadata.name || '';
  overlayImageURL.value = metadata.overlayImageURL || '';
});

const handleResponse = (response) => {
  loading.value = false;
  return response?.data?.results || response?.data?.result || [];
};

const onVisible = async () => {
  const contentTypes = [ 'Article', 'Video', 'Podcast' ];

  const results = await Api.getSeriesArticles(props.series, contentTypes, props.exclude, props.offset, props.rows)
    .then(handleResponse);

  articles.value = results;
  loading.value = false;
};
</script>

<!-- eslint-disable-next-line -->
<style lang="scss">
.series-carousel {
  margin-bottom: $martech-spacer-4;

  .header {
    margin-bottom: $martech-spacer-3;

    @include breakpoint(1200) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .group {
      display: flex;
      align-items: center;

      @media only screen and (max-width: 320px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    img {
      width: 130px;
      margin-left: $martech-spacer-2;

      @include breakpoint(1200) {
        width: auto;
        height: 35px;
      }
    }
  }

  .view-all {
    padding: $martech-spacer-3 $martech-spacer-3 0 0;
    display: flex;
    justify-content: flex-end;

    @include breakpoint(1200) {
      padding-left: $martech-spacer-4;
      padding-right: 0;
    }
  }

  .carousel {
    display: inline-flex;
    width: 100%;
    height: 115px;

    @include breakpoint(1024) {
      display: grid;
      grid-gap: $martech-spacer-3;
    }

    @include breakpoint(1200) {
      height: auto;
    }

    .slide {
      width: 100%;
      padding-right: $martech-spacer-2;

      @include breakpoint(1024) {
        padding-right: 0;
      }

      .content-wrapper {
        height: 120px;
      }
    }
  }

  .mobile-scroll {
    padding: 0;

    .carousel {
      scroll-snap-type: x mandatory;
      -webkit-overflow-scrolling: touch;
      overflow-x: scroll;
      padding: 0 $martech-spacer-3;
      width: 100vw;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      .slide {
        scroll-snap-align: center;

        &:last-of-type {
          padding-right: $martech-spacer-3;
        }
      }

      .slim-article-card {
        width: 300px;

        @media only screen and (max-width: 380px) {
          width: 260px;
        }

        &:last-of-type {
          padding-right: 0;
        }
      }
    }
  }
}
</style>
