<template>
  <on-enter-navigate-card :data="data">
    <router-link v-if="render" :to="link" tabindex="-1" :data-testid="componentID()" @click="onClick(data)">
      <div class="condensed" :class="{'yugioh' : vertical === 'yugioh'}">
        <div v-if="showVerticalLinks" class="condensed-labels">
          <p class="martech-labels martech-blue" :data-testid="componentID('vertical')">
            {{ verticalDisplay }}
          </p>
        </div>
        <div class="columns">
          <div class="column">
            <div class="name">
              <p class="martech-body martech-barlow martech-text-capitalize martech-one-line martech-text-bold" :data-testid="componentID('title')">
                {{ displayName }}
              </p>
              <companion-paw v-if="companionID"/>
            </div>
            <div class="group">
              <p class="martech-text-sm" :data-testid="componentID('date')">
                {{ formattedDate }}
              </p>
              <p class="martech-text-sm">
                <span class="martech-sm-divider">|</span>{{ format }}
              </p>
              <div v-if="vertical.toLowerCase() === 'magic'" class="colors">
                <span v-if="color" class="martech-sm-divider martech-text-sm">|</span> <mana-symbols :color="color" :size="16"/>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="event-group">
              <div class="inner-group">
                <p v-if="playerName" class="martech-text-sm" :data-testid="componentID('player')">
                  By <router-link tabindex="-1" class="player-link martech-text-sm" :to="`${deckPlayerLink}`" :data-testid="componentID('player-link')" @click.stop.prevent="navigate(`${deckPlayerLink}`)">
                    {{ playerName }}
                  </router-link>
                </p>
                <p v-if="eventRank" class="martech-text-sm" :data-testid="componentID('event-rank')">
                  <span class="martech-sm-divider martech-text-md">|</span>{{ eventPlacement }}
                </p>
              </div>
              <p v-if="eventName" class="martech-text-sm" :data-testid="componentID('event-name')">
                <span v-if="deviceType === 'desktop' && eventRank" class="martech-sm-divider martech-text-md">|</span> {{ eventName }}
              </p>
            </div>
            <market-price-template
              align-text="left"
              class="deck-card--price"
              :price="marketPrice"/>
          </div>
        </div>
      </div>
    </router-link>
  </on-enter-navigate-card>
</template>

<script>
import deviceType from '@/mixins/deviceType';
import CompanionPaw from '@/components/iconography/deck-icons/CompanionPaw.vue';
import ManaSymbols from '@/components/iconography/magic-mana-symbols/ManaSymbols.vue';
import { MarketPriceTemplate, VerticalHelpers as verts, EventsHelpers as events } from '@tcgplayer/martech-components';
import deck from '@/components/card-elements/Cards/mixins/deck';
import OnEnterNavigateCard from '@/components/card-elements/OnEnterNavigateCard.vue';

export default {
  name: 'condensed-deck',
  components: {
    CompanionPaw,
    ManaSymbols,
    MarketPriceTemplate,
    OnEnterNavigateCard,
  },
  mixins: [ deviceType, deck ],
  props: {
    authorUrl: {
      type: Object,
      required: false,
      default: null,
    },
    data: {
      type: Object,
      required: true,
      default: null,
    },
    link: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    onClick: {
      type: Function,
      required: false,
      default: (article) => {},
    },
    showVerticalLinks: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      render: false,
    };
  },
  computed: {
    // this.articleData is used in the deck mixin while in this case it's coming into the component as this.data
    articleData() {
      return this.data;
    },
    verticalDisplay() {
      return verts.displayName(this.vertical.toLowerCase());
    },
    deckPlayerLink() {
      return this.deckSource === 'affiliate'
        ? `/author/${this.playerName.replaceAll(' ', '-').toLowerCase()}/decks`
        : `${this.verticalLink}/decks/player/${this.playerName}`;
    },
    showEventRank() {
      return !(!this.eventRank || this.eventRank === '' || this.eventRank === '0' || this.eventRank === 0 || this.eventRank === '0-0' || this.eventRank === '-');
    },
    verticalLink() {
      return verts.verticalLink(this.vertical);
    },
    formattedDate() {
      return this.date.replaceAll('-', '/');
    },
    eventPlacement() {
      return events.placementText(this.eventRank);
    },
  },
  methods: {
    navigate(link) {
      window.location = link;
    },
  },
};
</script>

<style lang="scss" scoped>
.name {
  display: flex;
  align-items: center;
  text-transform: martech-text-capitalize;

  svg {
    margin-left: $martech-spacer-2;
  }
}

.condensed {
  padding: $martech-spacer-2 $martech-spacer-3;
  border-bottom: 1px solid $martech-border;
  color: $martech-gray-170;

  &-labels {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .columns {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: baseline;

    @include breakpoint(1200) {
      flex-direction: row;
    }

    .column {
      width: 100%;

      @include breakpoint(1200) {
        &:nth-child(1) {
          width: 40%;
        }

        &:nth-child(2) {
          border-left: 1px solid $martech-border;
          padding-left: $martech-spacer-3;
          width: 60%;
        }
      }
    }
  }

  .name {
    padding: $martech-spacer-1 0;
  }

  .colors {
    display: flex;
    align-items: center;
  }

  .group {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .event-group {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;

    @include breakpoint(1200) {
      align-items: center;
      flex-direction: row;
      padding-bottom: $martech-spacer-1;
    }

    p:first-of-type {
      padding-bottom: $martech-spacer-2;

      @include breakpoint(1200) {
        padding-bottom: 0;
      }
    }

    .inner-group {
      display: flex;
      align-items: center;
      padding-bottom: $martech-spacer-2;

      @include breakpoint(1200) {
        padding-bottom: 0;
      }

      .player-link {
        color: $martech-text-primary;
      }

      p:first-of-type {
        padding-bottom: 0;
      }
    }
  }
}

:deep .market-price { /* eslint-disable-line */
  &__title, &__price {
    p {
    font-size: $martech-type-13;
    line-height: 16px;
    letter-spacing: 0.4px;
    }
  }
}

:deep(.mana-symbols) {
  svg {
    margin-right: $martech-spacer-1;
  }
}
</style>
