<template>
  <ul :data-testid="componentID()" role="listbox" class="autocomplete shadow" tabindex="-1">
    <template v-if="searchResults">
      <li
        v-for="(value, key) in searchResults"
        :key="`${key}-${value}`"
        role="option"
        :aria-selected="key === selectedItem"
        :class="{
          'in-type': value.contentType === 'searchIn',
          'last': key === contentTypeCount,
          'selected': key === selectedItem,
          'orange-brand': siteConfig('global.orangeBrand'),
        }">
        <router-link :id="`autocomplete-selected-${key}`" class="martech-text-sm" :to="value.link" @click="itemClicked(value, key)">
          <img v-if="value.thumbnail" :src="value.thumbnail || fallbackImage" :alt="value.name" @error="cardFallbackImage($event)">
          <img v-else v-show="[ 'card' ].includes(value.contentType)" :src="fallbackImage" :alt="value.name">
          <div v-if="[ 'deck', 'format', ].includes(value.contentType)" class="martech-icon martech-deck-icon martech-gray martech-small"/>
          <div v-if="value.contentType === 'event'" class="martech-icon martech-event-icon martech-gray martech-small"/>
          <img v-if="value.contentType === 'series'" :src="value.iconImageURL || ''" :alt="value.name" @error="seriesFallbackImage($event)">
          <img v-if="value.contentType === 'tag'" :src="value.iconImageURL || ''" :alt="value.name" @error="seriesFallbackImage($event)">
          <img v-if="value.contentType === 'author'" :src="value.iconImageURL || authorFallbackImage" :alt="value.name" class="author" @error="setAuthorFallbackImage($event)">
          <span :class="{'text-group': value.contentType !== 'first', 'centered' : value.contentType === 'searchIn'}">
            <span>
              <template v-if="[ 'searchIn' ].includes(value.contentType)">
                {{ value.name }}
              </template>
              <strong v-else-if="[ 'first' ].includes(value.contentType)">
                "{{ value.name }}"
              </strong>
              <template v-for="(word, k) in nameSearchPieces(value.name)" v-else>
                <strong
                  v-if="inSearchTerm(word)"
                  :key="`${k}-${word}`"
                  class="in-search-term"
                  :class="{'orange-brand': siteConfig('global.orangeBrand')}">
                  {{ word.replace(/ /g, '\xa0') }}
                </strong>
                <template v-else>
                  {{ word.replace(/ /g, '\xa0') }}
                </template>
              </template>
            </span>
            <span v-if="['deck', 'card', 'event', 'format'].includes(value.contentType)" class="game-name">
              {{ verticalDisplay(value) }}&nbsp;
              <span v-if="value.contentType === 'deck'">Deck</span>
              <span v-if="value.contentType === 'event'">Event</span>
            </span>
            <span v-else-if="['author', 'series', 'tag'].includes(value.contentType)" class="game-name">
              {{ `${value.contentType[0].toUpperCase()}${value.contentType.substring(1)}` }}
            </span>
          </span>
          <chevron-right v-show="value.contentType === 'searchIn'" class="chevron-right"/>
        </router-link>
      </li>
    </template>
    <li class="advanced-deck-search">
      <base-button
        v-if="siteConfig('autocomplete.magicDeckSearch')"
        tabstop="0"
        icon-classes="martech-icon martech-left martech-deck-search-icon martech-black martech-small"
        btn-style="martech-black-outline"
        btn-size="martech-small"
        @clicked="magicDeckSearch"
        @click="$emit('close-autocomplete')">
        Magic Deck Search
      </base-button>
      <base-button
        v-if="siteConfig('autocomplete.yugiohDeckSearch')"
        tabstop="0"
        icon-classes="martech-icon martech-left martech-deck-search-icon martech-black martech-small"
        btn-style="martech-black-outline"
        btn-size="martech-small"
        @clicked="yugiohDeckSearch"
        @click="$emit('close-autocomplete')">
        Yu-Gi-Oh! Deck Search
      </base-button>
    </li>
  </ul>
</template>

<script>
import api from '@/api/api';
import ChevronRight from '@/components/iconography/ChevronRight.vue';
import magic from '@/lib/magic';
import deviceType from '@/mixins/deviceType';
import amplitudeEvent from '@tcgplayer/amplitude';
import {
  BaseButton,
  ImageHelper as image,
  YugiohHelpers as yugioh,
  VerticalHelpers as verts,
  StringHelper as strings
} from '@tcgplayer/martech-components';
import delve from 'dlv';

// Used to make sure we can't double up on the requests to the back-end
let dedupe = '';

export default {
  name: 'auto-complete',
  components: {
    BaseButton,
    ChevronRight,
  },
  mixins: [ deviceType ],
  props: {
    searchTerm: {
      type: String,
      required: true,
    },
    contentTypes: {
      type: Array,
      required: false,
      default: () => [ 'card', 'event', 'deck', 'article', 'format', 'author', 'series', ],
    },
    showAdvancedDeckSearch: {
      type: Boolean,
      required: false,
      default: true,
    },
    maxResultsPerType: {
      type: Number,
      required: false,
      default: 5,
    },
  },
  data() {
    return {
      originalTerm: null,
      selectedItem: -1,
      keyTermChange: false,
      searchResults: [],
      contentTypeCount: 0,
      authorFallbackImage: 'https://tcgplayer-cdn.tcgplayer.com/infinite/images/Content-Site-Placeholder-Images_Author_Dragon.svg',
    };
  },
  computed: {
    fallbackImage() {
      return image.getFallbackImage(null, 'ghostysvg');
    },
    searchTermRegex() {
      const term = this.originalTerm.trim().replace(/\s\s+/g, ' ');
      return new RegExp(`(${term.replace(/ /g, '|')})`, 'gi');
    },
  },
  watch: {
    searchTerm(term) {
      // This fixes empty spaces for search
      term = term.trim();

      // Clear out searchResults when people delete their search term
      if (!term) {
        this.searchResults = [];
        return;
      }

      if (term === dedupe) return;

      dedupe = term;

      if (this.keyTermChange) {
        // Used arrow keys to scroll up/down
        this.keyTermChange = false;
      } else {
        // typed in a new search term
        this.originalTerm = term;
        api.autocomplete(term)
          .then(this.processResponse);
      }
    },
  },
  mounted() {
    document.addEventListener('keydown', this.selectItem);
  },
  unmounted() {
    document.removeEventListener('keydown', this.selectItem);
  },
  methods: {
    cardFallbackImage(ev) {
      ev.target.src = this.fallbackImage;
    },
    seriesFallbackImage(ev) {
      // Hides the broken image but keeps the space to align the text with everything else
      ev.target.style.opacity = 0;
    },
    setAuthorFallbackImage(ev) {
      ev.target.src = 'https://tcgplayer-cdn.tcgplayer.com/infinite/images/Content-Site-Placeholder-Images_Author_Dragon.svg';
      ev.target.src = image.getFallbackImage(null, 'author');
    },
    arrowAction(change) {
      this.keyTermChange = true;
      this.selectedItem += change;

      if (this.selectedItem < 0) return;

      const newTerm = this.searchResults[this.selectedItem].display || this.searchResults[this.selectedItem].name;
      this.searchResults[0].name = newTerm || this.originalTerm;

      this.updateInTypes(this.searchResults[0].name);

      this.$emit('keyselect', this.selectedItem >= 0 ? newTerm : this.originalTerm);

      // Arrow keys update focus so that links are read by screen readers
      document.getElementById(`autocomplete-selected-${this.selectedItem}`)?.focus();
    },
    selectItem(ev) {
      if (this.searchResults.length < 2) return;
      if (ev.key === 'ArrowUp' && this.selectedItem >= 0) {
        this.arrowAction(-1);
      } else if (ev.key === 'ArrowDown' && this.selectedItem < this.searchResults.length - 1) {
        this.arrowAction(1);
      } else if (ev.key === 'Enter') {
        // Send them to the first (search term in articles) if nothing selected
        if (this.selectedItem < 0) this.selectedItem = 0;

        // We populate this from the search results, but if the user hits enter fast enough the search results have not returned
        // and you are getting what was already populated by the previous response which isn't the right thing.  We'll just
        // set it to the original term since we're just searching for that in articles.
        const originalTerm = delve(this, 'originalTerm', '') || '';
        this.searchResults[0] = {
          contentType: 'first',
          name: this.originalTerm,
          display: this.originalTerm,
          link: `/search?q=${originalTerm.toLowerCase()}&p=1&contentMode=article&game=all%20games&ac=1`,
        };

        // Follow link for the selected item
        this.itemClicked(this.searchResults[this.selectedItem], this.selectedItem);
        this.$router.push(this.searchResults[this.selectedItem].link);
        this.selectedItem = -1; // Reset for the next search
      }
    },
    inSearchTerm(word) {
      if (!word) return false;

      return delve(this, 'originalTerm', '').toLowerCase().split(' ').includes(word.toLowerCase());
    },
    itemClicked(item, position) {
      this.$emit('clicked');
      amplitudeEvent('infinite', 'infiniteSearchAutocompleteClick', {
        query: this.originalTerm,
        name: item.name,
        game: item.game,
        id: item.id,
        tcgPlayerID: item.tcgPlayerID,
        contentType: item.contentType,
        score: item.score,
        position,
      });
    },
    verticalDisplay(value) {
      return verts.displayName(value.game);
    },
    magicDeckSearch() {
      this.$router.push({ path: '/magic-the-gathering/decks/advanced-search' });
    },
    yugiohDeckSearch() {
      this.$router.push({ path: '/yugioh/decks/advanced-search' });
    },
    // Break apart the names based on words in our search term
    nameSearchPieces(name) {
      name = name.split(this.searchTermRegex);
      name = name.filter((item, pos) => name.indexOf(item) === pos);

      if (!name[0]) name.shift();
      if (!name[name.length - 1]) name.pop();

      return name.filter(Boolean);
    },
    buildLink(item, unique, autocompleteMatch = true) {
      if (unique) {
        // If we have a canonical URL from the backend use it.  If an event or deck does not
        // have a canonical then we'll just do a search on it.
        if (item.canonicalURL) {
          return item.canonicalURL;
        }

        if (item.contentType === 'card') {
          let url = `/search?q=${item.name.toLowerCase()}&p=1&contentMode=card&exact=1`;

          if (autocompleteMatch) {
            url += '&ac=1';
          }

          if (item.game) {
            url += `&game=${item.game}`;
          }

          return url;
        }
      }

      let contentMode = 'article';
      if (item.contentType === 'event') {
        contentMode = 'deck';
      } else if (item.contentType !== 'first') {
        contentMode = item.contentType;
      }

      const game = item.contentType === 'card' ? item.game : 'all%20games';

      let search = `/search?q=${item.name.toLowerCase()}&p=1&contentMode=${contentMode}`;

      if (autocompleteMatch) {
        search += '&ac=1';
      }

      if (item.contentType === 'event') {
        search += '&contentType=event';
      }

      if (game) {
        search += `&game=${game}`;
      }

      return search;
    },
    updateInTypes(name) {
      for (let i = 1; i < this.searchResults.length; i++) {
        if (this.searchResults[i].contentType !== 'searchIn') break;

        // Put together item to build proper link
        const item = JSON.parse(JSON.stringify(this.searchResults[i]));
        item.contentType = item.name.toLowerCase().replace(/^in (article|deck|card)s$/, '$1');
        item.name = name;
        item.display = name;

        this.searchResults[i].link = this.buildLink(item);
      }
    },
    addInTypes(items) {
      this.contentTypeCount = items.length + 1;

      if (!this.searchResults.length) return;

      // We always show "in Articles" after the first result because we currently don't know if a term will match or not.  This
      // needs to happen after any sort of rearrangement of items.
      this.searchResults.splice(1, 0, {
        contentType: 'searchIn',
        name: 'in Articles',
        display: this.originalTerm,
        link: `/search?q=${this.searchResults[0].name.toLowerCase()}&p=1&contentMode=article&game=all%20games&ac=1`,
      });

      // Splice in content type links for our first entry starting after "in Articles" which is at position 1 of the array
      items.forEach((item) => {
        if ([ 'article', 'card', 'deck', 'event', ].includes(item.contentType)) {
          item.name = this.searchResults[0].name;
          this.searchResults.splice(2, 0, {
            contentType: 'searchIn',
            name: `in ${item.contentType.charAt(0).toUpperCase()}${item.contentType.slice(1)}s`,
            display: this.originalTerm,
            link: this.buildLink(item),
          });
        }
      });
    },
    cleanResponse(result) {
      const results = [];
      const typeCount = {};
      const nameGame = {};
      const firstItemPerType = [];

      for (let i = 0; i < result.length; i++) {
        const res = result[i];

        // If the api returns a matching content type then skip it
        if ([ 'player' ].includes(res.contentType)) continue;

        if (res.contentType === 'card' && res.tcgPlayerID) {
          res.thumbnail = `https://tcgplayer-cdn.tcgplayer.com/product/${res.tcgPlayerID}_150w.jpg`;
        }

        const uniqueKey = `${res.contentType}-${delve(res, 'name', '').toLowerCase()}-${res.game}`;

        // Limit items of matching type, name, game to a single instance
        if (delve(nameGame, uniqueKey)) {
          nameGame[uniqueKey].count++;

          // Update the link of the first instance to not be a direct link
          results[nameGame[uniqueKey].firstPosition].link = this.buildLink(results[nameGame[uniqueKey].firstPosition], res.contentType === 'card');
          continue;
        }

        typeCount[res.contentType] = typeCount[res.contentType] ? typeCount[res.contentType] + 1 : 1;

        // Limit items of a given content type to a max number
        if (typeCount[res.contentType] <= this.maxResultsPerType) {
          nameGame[uniqueKey] = {
            firstPosition: results.length,
            count: 1,
          };

          if (res.contentType === 'author') {
            res.canonicalURL = `/author/${res.name}/`;
          }

          if (res.contentType === 'series') {
            res.canonicalURL = `/series/${res.name}`;
            res.name = strings.titleCase(res.name);
          }

          if (res.contentType === 'tag') {
            res.canonicalURL = `/topics/${res.name}`;
            res.name = strings.titleCase(res.name);
          }

          res.link = this.buildLink(res, true);
          results.push(res);

          if (typeCount[res.contentType] === 1 && res.contentType !== 'event') {
            firstItemPerType.push(JSON.parse(JSON.stringify(res)));
          }
        }
      }

      return { results, firstItemPerType };
    },
    addFormatDeckMatches(results) {
      // Add direct deck pages after the 'in *' line items
      results.unshift(...this.buildFormatItems(magic.getFormats(), 'magic-the-gathering'));
      results.unshift(...this.buildFormatItems(yugioh.getFormats(), 'yugioh'));
    },
    addFirstResult(results) {
      const item = {
        contentType: 'first',
        name: this.searchTerm,
      };
      item.link = this.buildLink(item, false, false);
      results.unshift(item);
    },
    processResponse(response) {
      const { results, firstItemPerType } = this.cleanResponse(delve(response, 'data.result', []));
      this.addFormatDeckMatches(results);
      this.addFirstResult(results);

      this.searchResults = results.slice(0, this.deviceType === 'desktop' ? 15 : 9);

      this.addInTypes(firstItemPerType);
    },
    buildFormatItems(formats, link) {
      const items = [];

      const term = this.searchTerm.toLowerCase();
      Object.keys(formats).forEach((format) => {
        // Must match first 4 characters of a format at the start of a word (beginning of string or whitespace in front)
        const piece = format.substr(0, 4);
        const match = `(^${piece})|(\\W${piece})`;
        const re = new RegExp(match, 'g');
        if (re.test(term)) {
          items.push({
            contentType: 'format',
            name: `${strings.titleCase(formats[format])} Decks`,
            game: `${link}`,
            link: `/${link}/decks/format/${formats[format]}`,
          });
        }
      });

      return items;
    },
  },
};
</script>

<style lang="scss" scoped>
.autocomplete {
  border-radius: $martech-radius-default;
  width: 100%;
  background-color: $martech-white;
  position: absolute;
  top: 45px;
  min-height: 58px;
  list-style: none;
  padding: $martech-spacer-2 0;
  z-index: 15;

  .author {
    border-radius: 100%;
  }

  li {
    padding: 0 $martech-spacer-3;
    min-height: 38px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: $martech-spacer-1 0;

    &.selected {
      background-color: $martech-blue-hover-lighter;
    }

    &:hover {
      background-color: $martech-blue-hover-light;

      &:last-child {
        background-color: $martech-white;
      }
    }

    &.orange-brand {
      &.selected {
        background-color: rgba(243, 109, 33, 0.15);
      }

      &:hover {
        background-color: rgba(243, 109, 33, 0.15);
      }
    }

    .text-group {
      display: flex;
      flex-direction: column;
      min-height: 38px;
      color: $martech-text-primary;

      &.centered {
        span {
          min-height: 38px;
        }
      }

      span {
        display: flex;
        align-items: center;
      }

      .in-search-term {
        &.orange-brand {
          color: $martech-cfb-orange-dark;
        }
      }
    }

    a { /* eslint-disable-line */
      display: flex;
      flex-direction: row;
      align-items: center;
      text-decoration: none;
      width: 100%;
      height: 100%;
      overflow: hidden;
      white-space: nowrap;
      letter-spacing: 0.5px;
      color: $martech-text-primary;

      img {
        width: 18px;
        margin-right: $martech-spacer-2;
        border-radius: 2px;
      }

      .martech-icon {
        margin-right: $martech-spacer-2;
        min-width: 18px;

        &.martech-author-icon {
          max-height: 13px;
        }
      }

      .chevron-right {
        width: 7.5px;
      }
    }

    &.in-type {
      padding-left: 24px;

      a { /* eslint-disable-line */
        justify-content: space-between;
      }

      &.last {
        border-bottom: 1px solid $martech-border;
      }
    }

    .game-name {
      font-size: $martech-type-11;
      color: $martech-text-subdued;
      letter-spacing: 0.5px;
      line-height: 10px;
    }

    &.advanced-deck-search {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      flex-direction: column;
      padding: $martech-spacer-2 $martech-spacer-3;

      @include breakpoint(768) {
        flex-direction: row;
      }

      @include breakpoint(1024) {
        flex-direction: column;
      }

      @include breakpoint(1500) {
        flex-direction: row;
      }

      span {
        width: 100%;
        &:first-of-type {
          margin-bottom: $martech-spacer-2;

          @include breakpoint(768) {
            margin-bottom: 0;
            margin-right: $martech-spacer-2;
          }

          @include breakpoint(1024) {
            margin-bottom: $martech-spacer-2;
            margin-right: 0;
          }

          @include breakpoint(1500) {
            margin-bottom: 0;
            margin-right: $martech-spacer-2;
          }
        }

        :deep(button) {
          width: 100%;
        }
      }
    }
  }
}
</style>
