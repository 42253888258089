export default {
  verticalLanding: {
    showBestiary: [ '' ],
    editorsPicks: true,
    latestEvents: [],
    latestDecks: [ 'magic' ],
    latestDecksType: {
      magic: 'featured',
    },
    formatSubNav: {
      verticals: [ 'magic', 'flesh and blood' ],
      filteredMagicArticleFormats: [ 'standard', 'modern', 'pioneer', 'legacy', 'limited' ],
      filteredMagicEventFormats: [ 'standard', 'modern', 'pioneer', 'legacy', 'limited' ],
      events: true,
    },
    wideContent: true,
  },
  navigation: {
    logo: 'https://mktg-assets.tcgplayer.com/content/channel-fireball/site-images/cfb-banner-logo.webp',
    logoAltText: 'Channel Fireball',
    customLogoWidth: true,
    premier: [],
  },
  footer: {
    orangeBrand: true,
  },
  account: {
    darkMode: true,
    buttonStyle: 'martech-white',
    iconStyle: 'martech-white',
  },
  relatedContentTemplate: {
    orangeBrand: true,
  },
  global: {
    dripTag: 'General CFB Content',
    darkMode: true,
    domain: 'channel fireball',
    popularVerticals: [ 'magic', ],
    magicFormats: {
      standard: 'standard',
      modern: 'modern',
      pioneer: 'pioneer',
      limited: 'limited',
      legacy: 'legacy',
    },
    fallback: {
      image: {
        basePath: 'https://tcgplayer-cdn.tcgplayer.com/channel-fireball/images/fallback/',
        card: 'https://tcgplayer-cdn.tcgplayer.com/channel-fireball/images/ghost_imageFallbackPlaceholder%402x.png',
        ghosty: 'https://tcgplayer-cdn.tcgplayer.com/channel-fireball/images/fallback/fallback-640x480_all@2x.jpg',
        ghostysvg: 'https://tcgplayer-cdn.tcgplayer.com/channel-fireball/images/ghosty-cfb.svg',
      },
    },
    orangeBrand: true,
    socialIcons: {
      facebook: 'https://tcgplayer-cdn.tcgplayer.com/channel-fireball/images/facebook-gray.svg',
      twitter: 'https://tcgplayer-cdn.tcgplayer.com/channel-fireball/images/twitter-gray.svg',
      instagram: 'https://tcgplayer-cdn.tcgplayer.com/channel-fireball/images/instagram-gray.svg',
      youtube: 'https://tcgplayer-cdn.tcgplayer.com/channel-fireball/images/youtube-gray.svg',
      patreon: 'https://tcgplayer-cdn.tcgplayer.com/channel-fireball/images/patreon-gray.svg',
      twitch: 'https://tcgplayer-cdn.tcgplayer.com/channel-fireball/images/twitch-gray.svg',
    },
    opengraph: {
      image: 'https://tcgplayer-cdn.tcgplayer.com/channel-fireball/images/channelfireball-opengraph.jpg',
      description: 'The best strategy from the best card game players in the world - Magic, Flesh and Blood and more!',
    },
  },
  autocomplete: {
    magicDeckSearch: true,
    yugiohDeckSearch: false,
  },
  search: {
    filterOptions: {
      'all games': 'All Games',
      magic: 'Magic: The Gathering',
      'flesh and blood': 'Flesh and Blood',
    },
    placeholder: 'Search ChannelFireball',
  },
};
